<script setup lang="ts">

</script>

<template>
  <div class="grid grid-cols-1 min-h-screen w-full md:grid-cols-2">
    <div class="flex items-center justify-center bg-background px-4 py-12 lg:px-8 sm:px-6">
      <div class="max-w-md w-full space-y-8">
        <NuxtLinkLocale class="mb12 flex justify-center" to="/">
          <Logo class="h12" />
        </NuxtLinkLocale>
        <slot />
      </div>
    </div>

    <div class="relative mx-auto h-[calc(100vh-var(--layout-header-height,0)-100px)] min-h-[470px] wfull p4">
      <div class="hfull flex flex-nowrap touch-manipulation overflow-x-auto overflow-y-hidden rd-2xl children:min-w-full">
        <div>
          <div class="relative hfull hidden after:(absolute inset-0 from-[rgba(0,0,0,0.02)] via-[rgba(0,0,0,0.04)_50%] via-[rgba(0,0,0,0.2)_60%] to-[rgba(0,0,0,0.4)] bg-gradient-to-b content-['']) md:block">
            <div class="relative hfull wfull">
              <img
                height="100%"
                width="100%"
                decoding="async"
                importance="high"
                fetchpriority="high"
                loading="eager"
                sizes="calc(100vw / 1)"
                src="/images/auth-img.jpg"
                data-testid="authImage"
                alt="Auth image"
                class="inline hfull wfull rounded-0 object-cover object-center-top align-bottom"
              >
            </div>

            <a data-testid="bannerTarget" class="absolute inset-0 z1" href="#" />

            <div class="pointer-events-none absolute inset-x-0 bottom-40 z1 flex flex-col items-center justify-center text-center">
              <span class="mb2 text-5xl text-white fw900 leading-13.5 uppercase">
                Track your orders
              </span>
              <span class="text-sm text-white fw700">
                Keep track the status of your orders
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
